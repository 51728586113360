import React from "react"
import style from "./inner-hero.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import Container from "../container/container"
import { GatsbyImage } from "gatsby-plugin-image"
import Breadcrumbs from "../global/breadcrumbs"

const InnerHero = ({ heading, text, image, breadcrumbs }) => {
  return (
    <div className={style.hero}>
      <Container>
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <BorderWrapper topColor="light" bottomColor="white">
          <div className={style.hero__image}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
          </div>

          <div className={style.hero__content}>
            <Container gutters width={"small"}>
              <h1>{heading}</h1>
              <p>{text}</p>
            </Container>
          </div>
        </BorderWrapper>
      </Container>
    </div>
  )
}

export default InnerHero
