import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import InnerHero from "../components/hero/inner-hero"
import ModuleArea from "../components/modular-content/module-area"
import Pathways from "../components/pathways/pathways"

const InnerPage = ({ data }) => {
  const { title, heroText, heroImage, content, pathways, seoMetaTags, id } =
    data.datoCmsInnerPage

  const breadcrumbs = [{ title: title, id: id }]

  return (
    <Layout seo={seoMetaTags}>
      <InnerHero
        heading={title}
        text={heroText}
        image={heroImage}
        breadcrumbs={breadcrumbs}
      />
      <ModuleArea blocks={content} />
      <Pathways data={pathways} bottomColor="light" />
    </Layout>
  )
}

export default InnerPage

export const query = graphql`
  query InnerPageQuery($id: String!) {
    datoCmsInnerPage(originalId: { eq: $id }) {
      title
      slug
      id: originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroText
      pathways {
        ...Pathways
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", h: "750", fit: "crop", q: 60 }
        )
        alt
      }
      content {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsLeadBlock {
          ...Lead
        }
        ... on DatoCmsAccordionBlock {
          ...Accordion
        }
      }
    }
  }
`
